
import React from 'react';
import { useGlobalState } from '../Context';

const Header = () => {
  const { state } = useGlobalState();

  return (
    <div className={state.gallery_mode? "parallax-content gallery_mode": "parallax-content"}>
        <h1>DekoWao</h1>
    </div>
  );
};


export default Header;
import React from 'react';
import { useGlobalState } from '../Context';

const Footer = () => {
  const { state } = useGlobalState();

  return (
    <footer className={state.gallery_mode? 'footer gallery_mode': 'footer'}>
      <div className='socialLinks'>
        <a href="https://www.facebook.com/profile.php?id=61553705188269" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-facebook"></i></a>
        <a href="https://www.instagram.com/dekowao" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-instagram"></i></a>
      </div>
      <div className='contactInfo'>
        <a href="mailto:info@dekowao.com">info@dekowao.com</a>
        <a href="phone:+46072456893">+46 072456893</a>
      </div>
    </footer>
  );
};

export default Footer;
import React from 'react';
import Carousel from '../components/carousel';
import ContactForm from '../components/contact_form';


const Home = () => {

  const products = [
    {beforeImage: "./images/scenarios/1.png"
      , pieces: [
        // copas
        { price: 'från 3kr', width: 15, height: 15, x: -0.5, y: -0.3 },
        // plato
        { price: 'från 4kr', width: 15, height: 15, x: -0.5, y: 0.4 },
      ] 
    }, 

    {beforeImage: "./images/scenarios/2.png", pieces: [
      // vaso
      { price: 'från 3kr', width: 15, height: 15, x: 0.1, y: 0.4 },
      // servilleta
      { price: 'från 5kr', width: 15, height: 15, x: -0.1, y: 0.1 },
    ]},

    {beforeImage: "./images/scenarios/5.png", pieces: [
      // mantel
      { price: 'från 50kr', width: 15, height: 15, x: 0.55, y: -0.26 },
      // plato
      { price: 'från 4kr', width: 15, height: 15, x: 0.55, y: 0.1 },
      // mantel
      { price: 'från 4kr', width: 15, height: 15, x: -0.2, y: 0.5 },
      // bucaro
      { price: 'från 15kr', width: 15, height: 15, x: 0.2, y: -0.4 },
    ]},
    {beforeImage: "./images/scenarios/11.png", pieces: [
      
    ]},
    {beforeImage: "./images/scenarios/20.png", pieces: []},
    {beforeImage: "./images/scenarios/21.png", pieces: []},
    {beforeImage: "./images/scenarios/22.png", pieces: []},
    {beforeImage: "./images/scenarios/23.png", pieces: []},
    {beforeImage: "./images/scenarios/24.png", pieces: []},

    {beforeImage: "./images/scenarios/25.png", pieces: []},
    //{beforeImage: "./images/scenarios/26.png", pieces: []},
    //{beforeImage: "./images/scenarios/27.png", pieces: []},
    //{beforeImage: "./images/scenarios/28.png", pieces: []},
    {beforeImage: "./images/scenarios/29.png", pieces: []},
    //{beforeImage: "./images/scenarios/30.png", pieces: []},
    {beforeImage: "./images/scenarios/31.png", pieces: []},
    {beforeImage: "./images/scenarios/32.png", pieces: []},
    {beforeImage: "./images/scenarios/33.png", pieces: []},
    //{beforeImage: "./images/scenarios/34.png", pieces: []},

    {beforeImage: "./images/scenarios/13.png", pieces: []}, 
    {beforeImage: "./images/scenarios/14.png", pieces: []},
    {beforeImage: "./images/scenarios/15.png", pieces: []},
    {beforeImage: "./images/scenarios/16.png", pieces: []}, 
    {beforeImage: "./images/scenarios/18.png", pieces: []},
    {beforeImage: "./images/scenarios/19.png", pieces: []},
  ];



  return (
    <React.Fragment>
        <Carousel 
            beforeImages={products} />

        <ContactForm/>
    </React.Fragment>
        
  );
};

export default Home;

import React, { createContext, useContext, useReducer } from 'react';

// Define the initial state
const initialState = {
  gallery_mode: true,
  notifications: [],
  loading: false,
};

// Create a context
const GlobalStateContext = createContext();

// Create a custom hook to access the context
export const useGlobalState = () => {
  return useContext(GlobalStateContext);
};

// Create a reducer function to handle state updates
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_GLOBAL_PROPERTY':
      return { ...state, [action.key]: action.value };
    
    case 'ADD_NOTIFICATION':
        return { ...state, notifications: [...state.notifications, action.notification] };
    
    case 'REMOVE_NOTIFICATION':
        return { ...state, notifications: state.notifications.filter((notification) => notification.id !== action.id) };

    default:
      return state;
  }
};

// Create the provider component
export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

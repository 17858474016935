import React, { useState, useEffect } from 'react';

const Images = ({imgs}) => {
  const [current_index, setcurrent_index] = useState(0);


  useEffect(() => {
    const timer2 = setInterval(() => {
      setcurrent_index(current_index => (current_index + 1) % imgs.length);
    }, 6000);

    return () => clearInterval(timer2);
  }, [ imgs.length ]);

    return (
        <div className="images">
            <img src={imgs[current_index]} className='default' alt="product" />
            {imgs.length > 1 && <div className='bullets'>
                {
                    imgs.map((_, index) => {
                    return <div onClick={(e) => setcurrent_index(index) } key={`bullet-${index}`} className={`bullet ${ index === current_index? 'active': '' }`}>
                    </div>
                    })   
                } 
            </div>}
        </div>
    )
}

const Product = ({ 
    title, description, price, imgs, defaultimg
}) => {
    return (
        <div className="product">
            <Images imgs={imgs} defaultimg={defaultimg} />
            <h2>{title}</h2>
            <p>{description}</p>
            <p>{price}</p>
        </div>
    )
}

const Hyr = () => {

    const products = [
        {
            title: "Stort tält",
            description: "6m x 12m",
            price: "3000 SEK/dag",
            imgs: [
            "./images/products/stor_telt.png",
            "./images/products/telt_stort2.png",
            ],
        },
        {
            title: "Candy kart",
            description: "",
            price: "300 SEK/dag",
            imgs: [
                "./images/products/candy1.png",
                "./images/products/candy2.png",
                "./images/products/candy3.png",
            ],
        },
        {
            title: "Stor nallebjörn ",
            description: "",
            price: "50 SEK/dag",
            imgs: [
                "./images/products/bear1.png",
                "./images/products/bear2.png",
            ],
        },
        {
            title: "Boxljus",
            description: "",
            price: "15 SEK/dag",
            imgs: [
                "./images/products/boxljus.png",
                "./images/products/box2.jpg",
            ],
        },
        {
            title: "Stol",
            description: "",
            price: "15 SEK/dag",
            imgs: [
                "./images/products/chair.webp"
            ],
            defaultimg: "./images/products/chair.webp"
        },
        {
            title: "Popcornmaskin",
            description: "",
            price: "100 SEK/dag",
            imgs: [
                "./images/products/popcorn.webp"
            ],
            defaultimg: "./images/products/popcorn.webp"
        },
        {
            title: "Bord",
            description: "",
            price: "50 SEK/dag",
            imgs: [
                "./images/products/table.webp",
                "./images/products/table3.png"
            ],
            defaultimg: "./images/products/table.webp"
        },
        {
            title: "Runt bord",
            description: "",
            price: "100 SEK/dag",
            imgs: [
                "./images/products/rund_table.png"
            ],
            defaultimg: ""
        },
        {
            title: "Bordstång",
            description: "",
            price: "150 SEK/dag",
            imgs: [
                "./images/products/ljus_armor.png",
            ],
            defaultimg: "./images/products/table.webp"
        },
        {
            title: "Blomvas",
            description: "",
            price: "15 SEK/dag",
            imgs: [
                "./images/products/bucaro.png",
            ],
            defaultimg: "./images/products/bucaro.png"
        },

        {
            title: "Vinglass",
            description: "",
            price: "3 SEK/dag",
            imgs: [
                "./images/products/glass.png",
            ],
            defaultimg: "./images/products/bucaro.png"
        },

        {
            title: "Ballong båge",
            description: "",
            price: "150 SEK/dag",
            imgs: [
                "./images/products/ball2.jpg",
                "./images/products/circle2.png",
                //"./images/products/circle3.png",
            ],
            defaultimg: "./images/products/bucaro.png"
        },

        {
            title: "Ljusstake",
            description: "",
            price: "50 SEK/st dag",
            imgs: [
                "./images/products/candel.png",
            ],
        },
        {
            title: "Bestick",
            description: "",
            price: "4 SEK/st dag",
            imgs: [
                "./images/products/fork.png",
                "./images/products/bestick2.png",
            ],
        },
        {
            title: "Tält",
            description: "",
            price: "50 SEK/dag",
            imgs: [
                "./images/products/indio.png",
                "./images/products/indio2.png",
            ],
        },
        {
            title: "Champagnekylare",
            description: "",
            price: "50 SEK/st dag",
            imgs: [
                "./images/products/champagnekylare2.png",
            ],
        },
        {
            title: "Chocklad fontän",
            description: "",
            price: "50 SEK/dag",
            imgs: [
                "./images/products/chocklad_fountain.png",
                "./images/products/chocklad_fountain2.png",
            ],
        },
        {
            title: "Foundeu",
            description: "",
            price: "50 SEK/dag",
            imgs: [
                "./images/products/fondeu.png",
            ],
        },
        {
            title: "Piedestal",
            description: "",
            price: "50 SEK/dag",
            imgs: [
                "./images/products/stativ.png",
                "./images/products/stativ2.png",
                "./images/products/stativ3.png",
            ],
        },

        {
            title: "Piedestal",
            description: "",
            price: "50 SEK/dag",
            imgs: [
                "./images/products/piedestal2.jpg",
                "./images/products/piedestal3.jpg",
            ],
        },
        {
            title: "Äppel låda",
            description: "",
            price: "50 SEK/st dag",
            imgs: [
                "./images/products/spell1.png",
            ],
        },
        {
            title: "Stativ",
            description: "160 cm",
            price: "30 SEK/st dag",
            imgs: [
                "./images/products/marco.png",
            ],
        },
        {
            title: "Termoskanna",
            description: "",
            price: "30 SEK/st dag",
            imgs: [
                "./images/products/termo1.png",
            ],
        },

        {
            title: "Löpare",
            description: "Färg: lilla, rosa, champagne, blå, grön",
            price: "10 SEK/st dag",
            imgs: [
                "./images/products/camino.png",
            ],
        },

        {
            title: "Rosett",
            description: "Färg: lilla, rosa, champagne, blå",
            price: "5 SEK/st dag",
            imgs: [
                "./images/products/cinta2.jpeg",
                "./images/products/cinta3.jpeg",
                "./images/products/cinta4.jpg",
            ],
        },
        {
            title: "Servett",
            description: "Färg: rosa, champagne, blå",
            price: "5 SEK/st dag",
            imgs: [
                "./images/products/servilleta1.jpeg",
                "./images/products/servilleta2.jpeg",
            ],
        },
        {
            title: "Glass",
            description: "",
            price: "3 SEK/st dag",
            imgs: [
                "./images/products/vaso.png",
            ],
        },
        {
            title: "Porslin",
            description: "",
            price: "4 SEK/st dag",
            imgs: [
                "./images/products/duka2.png",
            ],
        },

        {
            title: "Ballong stöd hållare",
            description: "",
            price: "10 SEK/dag",
            imgs: [
                "./images/products/butter.png",
                "./images/products/stang.webp",
            ],
        },

        {
            title: "Kake",
            description: "",
            price: "5 SEK/dag",
            imgs: [
                "./images/products/kake.jpg",
            ],
        },

        {
            title: "Lyktor",
            description: "",
            price: "5 SEK/dag",
            imgs: [
                "./images/products/lampa.webp",
                "./images/products/lampa2.webp",
            ],
        },

        {
            title: "Dekoration",
            description: "",
            price: "5 SEK/dag",
            imgs: [
                "./images/products/deko1.webp",
            ],
        },
        {
            title: "Gardiner",
            description: "Färg: vit, grön",
            price: "15 SEK/dag",
            imgs: [
                "./images/products/gardin.jpg",
                "./images/products/gardin2.jpg",
                "./images/products/gardiner.webp",
            ],
        },

        {
            title: "Hängande glass",
            description: "",
            price: "3 SEK/dag",
            imgs: [
                "./images/products/hanging_glass.jpg",
                "./images/products/hanging_glass2.jpg",
            ],
        },

        {
            title: "Duka",
            description: "Färg: vit",
            price: "25 SEK/dag",
            imgs: [
                "./images/products/mantel.webp",
            ],
        },

        {
            title: "Rund duka",
            description: "Färg: vit.",
            price: "50 SEK/dag",
            imgs: [
                "./images/products/rund_table.png"
            ],
        },

        {
            title: "Runda ballonger",
            description: "",
            price: "50 SEK/dag",
            imgs: [
                "./images/products/ballonger.webp"
            ],
        },


        {
            title: "Dekoration",
            description: "",
            price: "10 SEK/dag",
            imgs: [
                "./images/products/dekoration2.webp"
            ],
        },

        {
            title: "Champagne glass",
            description: "",
            price: "3 SEK/dag",
            imgs: [
                "./images/products/chglass.png",
                "./images/products/chglass2.png"
            ],
        },

        {
            title: "Vatten kanna",
            description: "",
            price: "5 SEK/st dag",
            imgs: [
                "./images/products/vatten.jpg"
            ],
        },

        {
            title: "Kryddkvarn",
            description: "",
            price: "4 SEK/st dag",
            imgs: [
                "./images/products/kryd.png"
            ],
        },
        {
            title: "Ballonglådor",
            description: "",
            price: "5 SEK/st dag",
            imgs: [
                "./images/products/box.jpg"
            ],
        },

        {
            title: "Ballongpump",
            description: "",
            price: "50 SEK/st dag",
            imgs: [
                "./images/products/pump.jpg"
            ],
        },
    ];
  

let [productsS, setProductsS] = useState(products);
let [search, setSearch] = useState('');

 const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);

    // filter the current products
    const filtered = products.filter((product) => {
        return !value || product.title.toLowerCase().includes(value.toLowerCase())
            || product.description.toLowerCase().includes(value.toLowerCase());
            
    });

    setProductsS(filtered);
 }

  return (
    <div className='hyr-container'>
        <h1 className="hyr-title">HYR SORTIMENT ({productsS.length})</h1>
        <h2 className='hyr-title2 alert'>* Vid skada eller förlust av någon av artiklarna kommer deras inköpspris att debiteras.</h2>
        <input
            type="text"
            name="search"
            min={1}
            placeholder="Hitta produkter"
            className='contactFormField'
            value={search}
            onChange={handleChange}
        />
        <div class="store">
            {
                productsS.map((product, index) => (
                    <Product 
                        key={index}
                        title={product.title}
                        description={product.description}
                        price={product.price}
                        imgs={product.imgs}
                        defaultimg={product.defaultimg}
                    />
                ))
            }
        </div>
    </div>
  );
};

export default Hyr;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  sv: {
    translation: {
      "Contact us!": "Kontakta oss!",
      "What is your name?": "Ditt namn",
      "Your Name": "Ditt namn",
      "Give your email so we can write to you?": "Din e-post",
      "Your Email": "Din e-post",
      "How can we talk to you?": "Ditt telefonnummer",
      "Your Phone": "Ditt telefonnummer",
      "How many people are you?": "Hur många personer är ni?",
      "Intended date": "Önskat datum",
      "How many guests do you plan to host?": "Hur många gäster planerar du att ta emot?",
      "Tell us more ! What is your dream?": "Berätta mer! Vad är din dröm?",
      "Your Message": "Ditt meddelande",
      "Contact": "Skicka",
      "An event decoration company": "En eventdekorationsföretag",
      "description": "Vår fokus ligger i eventdekoration, vilket ger en elegant atmosfär för dina speciella tillfällen. Dessutom har vi ett sortiment av högkvalitativ utrustning tillgänglig för uthyrning. Vi är stolta över vår flexibilitet och glada att diskutera förhandlingsbara priser för att möta dina behov. :)"
      // ... other Swedish translations
    }
  },
  en: {
    translation: {
        "Contact us!": "Contact us!",
        "What is your name?": "Your name",
        "Your Name": "Your Name",
        "Give your email so we can write to you?": "Your email",
        "Your Email": "Your Email",
        "How can we talk to you?": "Your phone",
        "Your Phone": "Your Phone",
        "How many people are you?": "How many people are you?",
        "Intended date": "Intended date",
        "How many guests do you plan to host?": "How many guests do you plan to host?",
        "Tell us more ! What is your dream?": "Tell us more ! What is your dream?",
        "Your Message": "Your Message",
        "Contact": "Contact",
        "An event decoration company": "An event decoration company",
        "description": "Our specialty lies in event decoration, providing an elegant ambiance for your special occasions. Furthermore, we have a range of high-quality equipment available for rent. We pride ourselves on flexibility and are happy to discuss negotiable pricing to meet your needs. :)"
      // ... other English translations
    }
  },
  es: {
    translation: {
      "Contact us!": "Contáctenos!",
      "What is your name?": "Tu nombre",
      "Your Name": "Tu nombre",
      "Give your email so we can write to you?": "Tu email",
      "Your Email": "Tu correo electrónico",
      "How can we talk to you?": "¿Cómo podemos hablar contigo?",
      "Your Phone": "Tu número de teléfono",
      "How many people are you?": "¿Cuántas personas sois?",
      "Intended date": "Fecha prevista",
      "How many guests do you plan to host?": "¿Cuántos invitados planeas recibir?",
      "Tell us more ! What is your dream?": "¡Cuéntanos más! ¿Cuál es tu sueño?",
      "Your Message": "Tu mensaje",
      "Contact": "Contacto",
      "An event decoration company": "Una empresa de decoración de eventos",
      "description": "Nos especializamos en la decoración de eventos, proporcionando un ambiente elegante para sus ocasiones especiales. Además, contamos con una gama de equipos de alta calidad disponibles para alquiler. Nos enorgullecemos de nuestra flexibilidad y estamos encantados de discutir precios negociables para satisfacer sus necesidades. :)"

      // ... other Spanish translations
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  //.use(LanguageDetector) // passes a language detector
  .init({
    resources,
    fallbackLng: "sv", // use sv if detected lng is not available

    // have a common namespace used around the full app
    ns: ["translation"],
    defaultNS: "translation",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
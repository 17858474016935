import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useGlobalState } from '../Context';


const LangSwitch = () => {
  const { i18n }  = useTranslation();

  const { state } = useGlobalState();
  const [showMenu, setShowMenu] = useState(false);
  const [currentFlag, setCrrentFlag] = useState('/images/flag-sv.png');

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setCrrentFlag(`/images/flag-${language}.png`);
    setShowMenu(false);
  };

  return (
    <div className={state.gallery_mode? 'languageSelector gallery_mode': 'languageSelector'}>
      <button onClick={() => setShowMenu(!showMenu)} className='languageButton'>
        <img  className='language-img' src={currentFlag} alt="SV" />
      </button>

      {showMenu && (
        <div className='languageDropdown'>
          <button onClick={() => changeLanguage('sv')} className='languageItem'>
            <img className='language-img' src='/images/flag-sv.png' alt="SV" />
            <label>Svenska</label>
          </button>
          <button onClick={() => changeLanguage('en')} className='languageItem'>
            <img  className='language-img' src='/images/flag-en.png' alt="EN" />
            <label>English</label>
          </button>
          <button onClick={() => changeLanguage('es')} className='languageItem'>
            <img  className='language-img' src='/images/flag-es.png' alt="ES" />
            <label>Spanish</label>
          </button>
        </div>
      )}
    </div>
  );
};

export default LangSwitch;
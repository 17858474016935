import React, { useRef, useState, useEffect } from 'react';
import { useGlobalState } from '../Context';

function ImageLocation({ x, y, price, description, name, width, height, imagePos}) {
  const [over, setover] = useState(false);
  const { offsetx, offsety } = imagePos;


  const { state } = useGlobalState();

  const position = { left: offsetx*x + offsetx, top: y*offsety + offsety };
  return (
    <React.Fragment>
      {(over || state.gallery_mode) && <div className='hyr-info'  style={{ ...position }}>
          {name && <h4 className='hyr-name'>{name}</h4>}
          <h5 className='hyr-price'>{price}</h5> 
          {description && <p className='hyr-desription'>{description}</p>}
      </div>}
      <div className='hyr' onClick={(e) => {e.preventDefault(); setover(!over)}} style={{ ...position, width: width, height: height }}>
      </div>
      <div className='hyr2' onClick={(e) =>{e.preventDefault(); setover(!over);}} style={{...position, width: width, height: height }}>
      </div>
    </React.Fragment>
  )
}

function Carousel({ beforeImages, currentIndex, index }) {
  const containerRef = useRef(null);


  const [current_index, setcurrent_index] = useState(0);

  /*function rotate(){
    setcurrent_index(current_index => (current_index + 1) % beforeImages.length);
  }*/

  useEffect(() => {
    const timer2 = setInterval(() => {
      setcurrent_index(current_index => (current_index + 1) % beforeImages.length);
    }, 3000);

    return () => clearInterval(timer2);
  }, [ beforeImages.length ]);



  const { state } = useGlobalState();

  const [sizes, setsizes] = useState({width: 0, height: 0});

  useEffect(() => {

    if(!containerRef || !containerRef.current) return;

    const windowsize = containerRef.current.clientWidth;
    const windowheight = containerRef.current.clientHeight;

    setsizes({width: windowsize, height: windowheight});
  }, [containerRef]);

  return (
    <div 
      className={`slider-container ${state.gallery_mode? 'gallery_mode': ''}`}
      ref={containerRef} 
    >
      
      {
        beforeImages.map((_, index) => {
          const { beforeImage, pieces } = beforeImages[index];

          // Get the size of the window to calculate the offset

          let ofx = sizes.width/2;
          let ofy = sizes.height/2;
          if(containerRef && containerRef.current){
            ofx = containerRef.current.clientWidth/2;
            ofy = containerRef.current.clientHeight/2;
          }

          return <React.Fragment>
            <div key={`before-${index}`} className={`before-image ${ index === current_index? 'show': 'hide' }`} style={{ backgroundImage: `url(${beforeImage})` }}>
                {
                  pieces.map((piece) => {
                    return <ImageLocation key={`${piece.name}${piece.x}${piece.y}`} x={piece.x} y={piece.y} price={piece.price} description={piece.description} name={piece.name} width={piece.width} height={piece.height} imagePos={{
                      offsetx: ofx,
                      offsety: ofy,
                    }} />
                  })
                }
            </div>
          </React.Fragment>
        })
      }
      
      <div className='bullets'>
          {
            beforeImages.map((_, index) => {
              return <div onClick={(e) => setcurrent_index(index) } key={`bullet-${index}`} className={`bullet ${ index === current_index? 'active': '' }`}>
              </div>
            })   
          }
      </div>
    </div>
  );
}

export default Carousel;
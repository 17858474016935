import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, children }) => {
  
    if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={'modalOverlay'}>
      <div className={'modal'}>
        <button onClick={onClose} className={'closeButton'}>&times;</button>
        <div className={'modalContent'}>
          {children}
        </div>
      </div>
    </div>,
    document.getElementById('modal-root') // You need to have a div with this id in your public/index.html
  );
};

export default Modal;
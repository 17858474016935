import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../Context';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    count: 1,
    date: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const { t } = useTranslation();


  const { state, dispatch } = useGlobalState();
  const submit = async (e) => {
    
    e.preventDefault();
    // validate

    if(!formData.name || !formData.email || !formData.message || !formData.phone || !formData.date || !formData.count) {
      // Show a toast
      console.log('Error, some fields are missing');
      return;
    }
    // Send language as well

    dispatch({
      type: 'UPDATE_GLOBAL_PROPERTY',
      key: 'loading',
      value: true,
    });

    fetch('/api/contact', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
      .then((response) => response.text())
      .then((data) => {

        // set the values to empty
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
          date: '',
          count: 1
        });



        dispatch({
          type: 'UPDATE_GLOBAL_PROPERTY',
          key: 'loading',
          value: false,
        });

      })
      .catch((error) => {
        console.error('Error:', error);


        dispatch({
          type: 'UPDATE_GLOBAL_PROPERTY',
          key: 'loading',
          value: false,
        });
        // Show this in toast.
      });

  }

  return (
    <React.Fragment>
        <form  className={state.gallery_mode? 'contactForm gallery_mode': 'contactForm normal'} onSubmit={submit} method='post'>
    <p>{t("description")}</p>
    <h3>{t('Contact us!')}</h3>
        <label>{t('What is your name?')}</label>
        <input
            type="text"
            name="name"
            disabled={state.loading}
            placeholder={t("Your Name")}
            className='contactFormField'
            value={formData.name}
            onChange={handleChange}
        />
        <label>{t("Give your email so we can write to you?")}</label>
        <input
            type="email"
            name="email"
            disabled={state.loading}
            placeholder={t("Your Email")}
            className='contactFormField'
            value={formData.email}
            onChange={handleChange}
        />
        <label>{t("How can we talk to you?")}</label>
        <input
            type="phone"
            name="phone"
            disabled={state.loading}
            placeholder="+46"
            className='contactFormField'
            value={formData.phone}
            onChange={handleChange}
        />
        <label>{t("Intended date")}</label>
        <input
            type="text"
            name="date"
            disabled={state.loading}
            placeholder={t("December 24, 2024")}
            className='contactFormField'
            value={formData.date}
            onChange={handleChange}
        />
        <label>{t("How many guests do you plan to host?")}</label>
        <input
            type="number"
            name="count"
            min={1}
            disabled={state.loading}
            placeholder="30"
            className='contactFormField'
            value={formData.count}
            onChange={handleChange}
        />
        <label>{t("Tell us more ! What is your dream?")}</label>
        <textarea
            name="message"
            disabled={state.loading}
            placeholder={t("Your Message")}
            className='contactFormField'
            value={formData.message}
            onChange={handleChange}
        />
        <button type="submit" 
            disabled={state.loading} className='contactFormSubmit'>{t("Contact")}</button>
        </form>
    </React.Fragment>
  );
};

export default ContactForm;
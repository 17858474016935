import React, { useState, useEffect } from 'react';
import './i18n';

import './BeforeAfterSlider.css';
import './ContactForm.css';
import './App.css';
import './LanguageSelector.css';
import './Product.css';
import './Modal.css';
import './Piece.css';
import './Nav.css';
import './Store.css';

import Footer from './components/footer';
import LangSwitch from './components/lang';
import { GlobalStateProvider } from './Context';
import { Helmet } from "react-helmet";
import Nav from './components/menu';
import Home from './pages/home';
import Hyr from './pages/hyr';
import Header from './components/header';
import Info from './components/info';


import { Routes, Route } from "react-router-dom";


function App() {

  const calculateTimeLeft = () => {
    const difference = +new Date('2024-04-07') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 6000);

    return () => clearTimeout(timer);
  });

  //const size = sliders.length;

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{' '}
      </span>
    );
  });


  return (
      <GlobalStateProvider>
        <Helmet>
            <title>Dekowao</title>
            <meta
              name="description"
              content="Event dekoration företag"
            />
            <meta
              name="keywords"
              content="Event, Party, Decoration, Dekoration, Fest, Dekowao, Dekowao.se, Dekowao.com, Dekowao.eu, Stockholm, Sverige, Sweden, Europe, Europa, Dekoration, Dekorationer, Dekor, Hyra, Hyr"
            />
          </Helmet>
          <LangSwitch />
          <Nav />
          <Header />
          <Info />
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path={"/hyr"} element={<Hyr/>} />
          </Routes>
          <Footer/>
      </GlobalStateProvider>
  );
}

export default App;

// import { useTranslation } from 'react-i18next';
import React from 'react';
import { NavLink as Link } from "react-router-dom"
import routes from "../pages/paths";


const Nav = () => {

  return (
    <div className={'navigator'}>
        <div  className={`nav-item`}><Link activeClassName="active" to={routes().HOME}>Hem</Link></div>
        <div  className={`nav-item`}><Link activeClassName="active" to={routes().HYR}>Hyr sortiment</Link></div>
    </div>
  );
};

export default Nav;